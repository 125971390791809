var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-6",attrs:{"headers":_vm.head,"items":_vm.data,"item-class":"primary--text","mobile-breakpoint":"960","fixed-header":"","height":_vm.data.length > 5 ? '500' : '',"items-per-page":-1,"footer-props":_vm.data_table_options.footerProps},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"65px","height":"70px","margin":"10px","border-radius":"50px"},attrs:{"src":item.image != '' ? _vm.imgUrl + item.image : _vm.blankIMG,"width":"50px"}})]}},{key:"item.productDescription",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nick_name))]),_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(item.other_code))])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"placeholder":"0","type":"number","rules":_vm.generalRule},on:{"change":function($event){return _vm.$emit('TotalPriceQty')}},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", _vm._n($$v))},expression:"item.number"}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"prefix":"$","placeholder":"0","type":"number","rules":_vm.generalRule},on:{"change":function($event){return _vm.$emit('TotalPriceQty')}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", _vm._n($$v))},expression:"item.price"}})]}},{key:"item.arrived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.arrived"))+": "+_vm._s(item.real_number)+" + "+_vm._s(item.real_give_number)+" ("+_vm._s(_vm.$vuetify.lang.t("$vuetify.GiveAway"))+") ")]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"blue","dark":""}},[_vm._v(" $ "+_vm._s(_vm.getTotal(item.number, item.price))+" ")])]}},{key:"item.deleteProduct",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }